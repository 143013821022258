import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Box, TextField, Checkbox, FormControlLabel, Autocomplete } from "@mui/material";
import { Information } from "./Information";
import { ManagementForm } from "./ManagementForm";
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ModalSimpleButton from '../../../components/Modal/ModalSimpleButton';
import { DatePickerKeyDownEvent } from "../../../global";
import FileDragAndDrop from "../../../components/FileDragAndDrop";
import NewChemicalAuthorizationRequest from "../../../api/Inventory/NewChemicalAuthorizationRequest"
import NCAFileAttachment from "../../../api/Inventory/NCAFileAttachment"
import EmailAddressTextField from "../../../components/EmailAddressField";
import UnitOfMeasureApi from "../../../api/Admin/UnitOfMeasure";
import { GlobalTabCollection, StyledTab } from "../../../pages/styles";
import { GlobalButton } from "../../styles";


const StyledShortTextField = styled(TextField)({
  id: "outlined-normal",
  width: "25%",
  marginRight: "15px"
});
const NewChemicalAuthorization = ({ ...props }) => {
  let defaultErrorChecks = {
    requestedForEmail: null,
  }

  const [tabValue, setTabValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const [, setSelectedItems] = useState([])
  const [isChecked, setIsChecked] = useState(false);
  const handleCheck = () => {
    setIsChecked(!isChecked);
  };

  const day = new Date().getDate()
  const month = new Date().getMonth()
  const year = new Date().getFullYear()
  const requiredDate = useState(new Date(year, month, day));
  const [errorChecks, setErrorChecks] = useState(defaultErrorChecks)

  const [modalMessagesOpen, setModalMessagesOpen] = useState(false)
  const [modalMessagesText, setModalMessagesText] = useState('')
  const [modalMessagesTitle, setModalMessagesTitle] = useState('')
  const [modalMessagesButtonText, setModalMessagesButtonText] = useState('Ok')
  const [disabledSubmit, setDisabledSubmit] = React.useState(false);

  const [uoMs, setUoMs] = useState([])
  const [files, setFiles] = useState([])

  const errorMsg1 = 'To submit your form, you must provide a Chem ID and Product/Chemical Name and a file must be attached.';
  const currentDate = requiredDate && requiredDate[0];

  function openModalMessages(title, text) {
    setModalMessagesOpen(true);
    setModalMessagesTitle(title);
    setModalMessagesText(text);
  }

  function closeModalMessages() {
    setModalMessagesOpen(false);
    setModalMessagesText('')
    setModalMessagesButtonText('Ok')
    setModalMessagesTitle('')
  }

  const [submitInputsValue, setSubmitInputsValue] = useState({
    requestComments: "",
    requestedForEmail: '',
    requiredDate: currentDate,
    employeeSection: "",
    productName: "",
    productChemID: "",
    isEngineeredNanomaterial: false,
    estimatedAnnualPurchaseAmount: "",
    estimatedAnnualPurchaseAmountUoMName: '',
    intendedUse: "",
    knownSafetyRequirements: "",
    isNewToSite: false,
    chemicalIdentification: null,
    reviewerNotes: null,
    nfpah: null,
    nfpaf: null,
    nfpar: null,
    hmish: null,
    hmisf: null,
    hmisr: null,
    isChronic: false,
    isPHSCat1: false,
    isPHSCat2: false,
    isPHSCat3: false,
    isPHSCat4: false,
    isEngineeredNanoMaterial: false,
    isPeroxidizable: false,
    reviewedByEmail: null,
    approvedDate: null,
    midasChemID: null,
    statusName: null,
  });
  const createNewChemAuthRequest = async (e) => {
    e.preventDefault()

    if (!(submitInputsValue.requiredDate >= currentDate)) return;

    const {
      productName,
      productChemID,
      requestedForEmail,
    } = submitInputsValue;

    if ((productName !== '' && productChemID !== '' && requestedForEmail !== '' && files && files.length > 0)) {
        submitInputsValue.isNewToSite = isChecked;
        setDisabledSubmit(true);
        const myResult = await NewChemicalAuthorizationRequest.chemicalAuthorizationRequest(submitInputsValue)

        if (myResult.message === 'Success')
        {
          if (files.length > 0) {
            sendFiles(myResult.result.id)
          } else {
            setDisabledSubmit(false);
            openModalMessages("NCA Saved Successfully", "You request has been saved!")
          }  
        } else {
          setDisabledSubmit(false);
          if (myResult.message)
          {
            openModalMessages("NCA Save Failed", `Your request failed to save.  ${myResult.message}. Contact support if you feel this is an error.`);
          } else {
            openModalMessages('NCA Save Failed', `Your request failed to save.  Unspecified Error, Contact support if you feel this is an error.`);
          }
        }

        setSelectedItems([])
        setSubmitInputsValue({
          ...submitInputsValue,
          requestComments: "",
          estimatedAnnualPurchaseAmount: "",
          estimatedAnnualPurchaseAmountUoMName: "",
          employeeSection: "",
          productName: "",
          productChemID: "",
          isEngineeredNanomaterial: false,
          intendedUse: "",
          knownSafetyRequirements: "",
          isNewToSite: false,
          chemicalIdentification: "",
          reviewerNotes: "",
          nfpah: "",
          nfpaf: "",
          nfpar: "",
          hmish: "",
          hmisf: "",
          hmisr: "",
          isChronic: false,
          isPHSCat1: false,
          isPHSCat2: false,
          isPHSCat3: false,
          isPHSCat4: false,
          isEngineeredNanoMaterial: false,
          isPeroxidizable: false,
          reviewedByEmail: "",
          approvedDate: "",
          midasChemID: "",
          statusName: "",
          requestedForEmail: '',
          requiredDate: currentDate
        });
    }
    else {
      openModalMessages('NCA Save Failed', errorMsg1);
    }
  }

  useEffect(() => {
    UnitOfMeasureApi.getAll().then((uom) => {
      setUoMs(uom.filter((result) => (result.type === "weight" || result.type === "volume") && result.isActive === true).sort((a, b) => a.uoMName.localeCompare(b.uoMName)));
    })
  }, [])

  function sendFiles(id) {
    const lastIndex = files.length - 1;
    let error = false;
    files.forEach((file, index) => {

      const formData = new FormData();
      formData.append("file", file.file);
      formData.append("NCAID", id);

      NCAFileAttachment.postAttachs(formData)
        .then((res) => {
          if (lastIndex === index) {
            const message = error ? "Form submitted succesfully, but Something went wrong with the attatchments, please try again" : "Form submitted succesfully"
            openModalMessages("Form Submitted", message)
            // setModalMessage(message)
            // setIsModalOpen(true)
            setFiles([])
            setDisabledSubmit(false);
          }
        })
        .catch((resp) => {
          error = true
          if (lastIndex === index) {
            const message = error ? "Form submitted succesfully, but Something went wrong with the attatchments, please try again" : "Form submitted succesfully"
            openModalMessages("Form Submitted", message)
            // setModalMessage(message)
            // setIsModalOpen(true)
            setFiles([])
            setDisabledSubmit(false);
          }
        });
    })
  }

  function handleFiles(newFiles) {
    const listFiles = newFiles.map(item => {
      return {
        file: item,
        sendWithShipment: false,
        isCOA: false
      }
    })
    setFiles(files.concat(listFiles))
  }

  function deleteFile(file) {
    const newFiles = files.filter(f => f.file !== file)

    setFiles(newFiles)
  }

  const renderSearchTab = () => {
    switch (tabValue) {
      case 0: {
        return (
          // <form>
            <div>
              <div>
                <span className='pageheader'>New Chemical SHE Review Request Form </span>
              </div>
              {/* Requestor Information */}
              <div className='small-header'>
                <span>Requester Information: </span>
              </div>

              <div style={{ display: "flex" }}>
                {/* <StyledShortTextField size="small" margin="normal" variant="outlined" label="Request Email*" InputLabelProps={{ shrink: true }}
                  value={submitInputsValue.requestedForEmail}
                  onChange={(e) => setSubmitInputsValue({ ...submitInputsValue, requestedForEmail: e.target.value })}
                ></StyledShortTextField>
                <GlobalButton
                  name="btnMe"
                  variant='contained'
                  style={{ marginTop: "17px", marginRight: "15px" }}
                  onClick={e => setSubmitInputsValue({ ...submitInputsValue, requestedForEmail: currentUser.username })}
                >+Me
                </GlobalButton> */}
                <EmailAddressTextField
                  fontSize={16}
                  fieldWidth={"25%"}
                  validatedUserEmail={submitInputsValue.requestedForEmail}
                  setValidatedUserEmail={(value) => setSubmitInputsValue({ ...submitInputsValue, requestedForEmail: value })}
                  hasErrors={errorChecks.requestedForEmail === null ? false : errorChecks.requestedForEmail}
                  setHasErrors={(value) => setErrorChecks({ ...errorChecks, requestedForEmail: value })}
                  isDisabled={false}
                  labelText="Requester Email*"
                  placeholderText="Requester Email*"
                  showPlusMeButton={true}>
                </EmailAddressTextField>

                <StyledShortTextField style={{ width: 'calc(25% - 74px)', marginLeft: '20px' }} size="small" margin="normal" variant="outlined" label="Section" InputLabelProps={{ shrink: true }}
                  value={submitInputsValue.employeeSection}
                  onChange={(e) => setSubmitInputsValue({ ...submitInputsValue, employeeSection: e.target.value })}
                ></StyledShortTextField>
              </div>

              <div style={{ display: "flex" }}>
                <StyledShortTextField style={{ id: "outlined-normal", width: "51.45%", marginRight: "15px" }} size="small" margin="normal" variant="outlined" label="Request Comments" InputLabelProps={{ shrink: true }}
                  value={submitInputsValue.requestComments} onChange={(e) => setSubmitInputsValue({ ...submitInputsValue, requestComments: e.target.value })}></StyledShortTextField>

              </div>
              <div style={{ display: "flex", paddingTop: '15px' }}>
                <div style={{ width: '60%', 
                  display: 'flex',
                  alignItems: 'center',
                  gap: '20px'
                }} className="submissionFormDatePicker">

                  {/* <StyledShortTextField size="small" margin="normal" variant="outlined" label="Date Requested By" InputLabelProps={{ shrink: true }}
                value={submitInputsValue.requiredDate}
                onChange={(e) => setSubmitInputsValue({...submitInputsValue, requiredDate: e.target.value})}></StyledShortTextField>  */}
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      label="Date Requested By"
                      value={submitInputsValue.requiredDate}
                      onChange={(e) => { setSubmitInputsValue({ ...submitInputsValue, requiredDate: e }) }}
                      renderInput={(params) => <TextField onKeyDown={DatePickerKeyDownEvent} size="small" {...params} />}
                      minDate={currentDate}
                    />
                  </LocalizationProvider>
                  <label>Typical turnaround is 5-7 business days</label>
                </div>
              </div>
              {/* Chemical Information */}
              <div className='small-header'>
                <span>Chemical Information: </span>
              </div>

              <div style={{ display: "flex" }}>
                <StyledShortTextField size="small" margin="normal" variant="outlined" label="Product/Chemical Name*" InputLabelProps={{ shrink: true }}
                  value={submitInputsValue.productName}
                  onChange={(e) => setSubmitInputsValue({ ...submitInputsValue, productName: e.target.value })}></StyledShortTextField>

                <StyledShortTextField size="small" margin="normal" variant="outlined" label="Chem ID*" InputLabelProps={{ shrink: true }}
                  value={submitInputsValue.productChemID}
                  onChange={(e) => setSubmitInputsValue({ ...submitInputsValue, productChemID: e.target.value })}></StyledShortTextField>

              </div>

              <div style={{ display: "flex" }}>
                <StyledShortTextField style={{ width: "18%" }} type="number" size="small" margin="normal" variant="outlined" label="Estimated Annual Quantity"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ inputProps: { min: 0 } }}
                  value={submitInputsValue.estimatedAnnualPurchaseAmount}
                  onChange={(e) => {
                    const value = e.target.value < 0 ? 0 : e.target.value;
                    setSubmitInputsValue({ ...submitInputsValue, estimatedAnnualPurchaseAmount: value })
                  }}></StyledShortTextField>
                <Autocomplete
                  size="small"
                  noOptionsText={"Loading UoMs..."}
                  value={submitInputsValue.estimatedAnnualPurchaseAmountUoMName}
                  onChange={(e, value) => {
                    setSubmitInputsValue({ ...submitInputsValue, estimatedAnnualPurchaseAmountUoMName: value });
                  }}
                  disablePortal
                  options={uoMs.map((uom) => uom.uoMName)}
                  sx={{ width: "12rem" }}
                  style={{ marginRight: "15px" }}
                  renderInput={(params) => <TextField size="small" margin="normal" {...params} label="UoM" />}
                />

                <FormControlLabel control={<Checkbox checked={submitInputsValue.isEngineeredNanomaterial}
                  value={submitInputsValue.isEngineeredNanomaterial}
                  onChange={(e) => { setSubmitInputsValue({ ...submitInputsValue, isEngineeredNanomaterial: e.target.checked }) }}
                ></Checkbox>} label="Engineered Nanomaterial" />

              </div>
              <div style={{ display: "flex" }}>
                <StyledShortTextField style={{ id: "outlined-normal", width: "51.45%", marginRight: "15px" }} size="small" margin="normal" variant="outlined" label="Intended Use" InputLabelProps={{ shrink: true }}
                  value={submitInputsValue.intendedUse}
                  onChange={(e) => setSubmitInputsValue({ ...submitInputsValue, intendedUse: e.target.value })}></StyledShortTextField>
              </div>
              <div style={{ display: "flex" }}>
                <StyledShortTextField style={{ id: "outlined-normal", width: "51.45%", marginRight: "15px" }} size="small" margin="normal" variant="outlined" label="Special SHE Requirements" InputLabelProps={{ shrink: true }}
                  value={submitInputsValue.knownSafetyRequirements}
                  onChange={(e) => setSubmitInputsValue({ ...submitInputsValue, knownSafetyRequirements: e.target.value })}></StyledShortTextField>
              </div>
              <div style={{ display: "flex" }}>
                <FormControlLabel style={{ width: "26%" }} control={<Checkbox checked={isChecked}
                  value={submitInputsValue.isNewToSite}
                  onChange={(e) => handleCheck()}
                ></Checkbox>} label="New To Site" />
                <FormControlLabel control={<Checkbox checked={!isChecked}
                  onChange={(e) => handleCheck()}
                ></Checkbox>} label="Not registered in last 3 years" />

              </div>
              <div style={{ display: "flex", width: "51.45%" }}>
                <FileDragAndDrop 
                  handleFiles={handleFiles} 
                  files={files.map((item) => item.file)} 
                  showFiles={true} 
                  handleDeleteFile={(file) => deleteFile(file)}
                  message={"Drag and drop your SDS file here"}
                ></FileDragAndDrop>
              </div>
              <div style={{ display: "flex", width: "79%" }}>
                <GlobalButton name="submit"
                  variant='contained'
                  style={{ textTransform: "none", marginTop: "50px", marginRight: "10px" }}
                  onClick={(e) => {
                    createNewChemAuthRequest(e)
                  }}
                  disabled={disabledSubmit}
                >Submit</GlobalButton>
              </div>
              <Information />

              <ModalSimpleButton title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>
                <label>
                  {modalMessagesText}
                </label>
              </ModalSimpleButton>

            </div>
        );
      }
      case 1: {
        return (<ManagementForm setTabValue={props.setTabValue} />);
      }
      default: {
        alert(tabValue);
      }
    }
  };

  return (
    <div>
      <div>
        <div>
          <Box sx={{ bgcolor: "#fff"}}>
            <GlobalTabCollection value={tabValue} onChange={handleChange} aria-label='ant example'>

              <StyledTab label='Submission Form' />
              <StyledTab label='Management Form' />

            </GlobalTabCollection>
            <Box sx={{ p: 3 }} />
          </Box>
          {renderSearchTab()}
        </div>
      </div>
    </div>
  );
};

export default NewChemicalAuthorization;